.wysiwyg {
    ul,
    ol {
        @apply pl-3 space-y-0.5;
    }

    ul > li {
        @apply flex;

        &::before {
            content: "";
            @apply mt-2 mr-3 block w-[0.2rem] h-[0.2rem] bg-black rounded-full shrink-0 lg:mt-[7px] lg:mr-4 lg:w-1 lg:h-1 xl:mt-2.5;
        }
    }

    ol > li {
        @apply list-decimal list-inside;
    }
}
