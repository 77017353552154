footer{
    .lang_links{
        a{
            @media (min-width: 768px) {
                width: 48px;
                height: 48px;
                font-size: 24px;
                padding-bottom: 3px;
            }
        }
        
    }
    .social_links{
        a{
            &:active,
            &:hover{
                svg{
                    filter: invert(1);
                }
                
            }

            @media (min-width: 768px) {
                width: 48px;
                height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &.linkedin{
                svg{
                    @media (min-width: 768px) {
                        width: 21px;
                        height: 21px;
                    }
                }
            }
            &.instagram{
                svg{
                    @media (min-width: 768px) {
                        width: 24px;
                        height: 24px;
                    }
                }

            }
            &.facebook{
                svg{
                    @media (min-width: 768px) {
                        width: 13px;
                        height: 24px;
                    }
                }

            }
        }
        
    }
}