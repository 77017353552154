.AB-Accordion {
    ul[data-outer-dividers="true"] {
        .pimcore_editable_block {
            @apply divide-y first:border-t last:border-b;
        }
    }
    
    ul[data-outer-dividers="false"] {
        .pimcore_editable_block {
            @apply divide-y;
        }
    }
}
