.AB-TwoColumnTextBoxes {
    .pimcore_editable_image {
        @apply relative;
        width: 100% !important;
        height: 100% !important;

        img {
            @apply absolute inset-0 w-full h-full object-contain object-center;
        }

        .pimcore_editable_image_alt {
            @apply z-10;

            > div {
                width: 100% !important;
                @apply mx-auto max-w-md;
            }
        }
    }
}
