.AB-FullsizeColorImageText {
    .wysiwyg {
        .highlight {
            @apply text-48 font-saa-series lg:text-60;
        }

        .topline {
            @apply text-24 md:text-20 xl:text-24;
        }
    }
}
