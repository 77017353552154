@tailwind base;
@tailwind components;
@tailwind utilities;

@import "admin";
@import "base";
@import "buttons";
@import "common";
@import "components";
@import "gdpr-consent";
@import "inputs";
@import "import-fonts";
@import "lenis";
@import "plyr";
@import "swiper";
@import "wysiwyg";

@import "elements/menu";
@import "elements/footer";
