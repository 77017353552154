@layer base {
    html,
    body {
        @apply m-0 box-border scroll-pt-52 md:scroll-pt-20 lg:scroll-pt-24 xxl:scroll-pt-28;
    }

    body {
        @apply font-apercu;
    }

    a {
        @apply underline-offset-2 hover:underline;
    }
}

@layer utilities {
    [x-cloak] {
        display: none !important;
    }

    .vertical-rl {
        writing-mode: vertical-rl;
    }
}
